.sente_pagination {
  padding: 20px;
  background: #fff;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sente_pagination__container {
  float: right;
  display: flex;
  align-items: center;
}
.sente_pagination__pages {
  display: inline-block;
}
.sente_pagination__pages_link:nth-child(n + 2) {
  margin-left: 4px;
}
.sente_pagination__pages_link_active {
  border-color: transparent;
  color: #ff7519;
  cursor: default;
}
.sente_pagination__goto {
  display: inline-block;
  margin-left: 20px;
}
.sente_pagination__goto_text {
  display: inline-block;
  color: #666;
  line-height: 26px;
}
.sente_pagination__goto_trigger {
  display: inline-block;
  font-size: 14px;
  color: #666;
  min-width: 18px;
  height: 26px;
  line-height: 26px;
  border: 1px solid #dae2ed;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0 16px;
}
.sente_pagination__pages_dot:nth-child(n + 2) {
  margin-left: 4px;
}

.sente_pagination__pages_dot {
  display: inline-block;
  font-size: 14px;
  color: #666;
  min-width: 18px;
  height: 26px;
  line-height: 26px;
  text-align: center;
}
.sente_pagination__goto_trigger:hover {
  color: #ff7519;
  border-color: #ff7519;
  text-decoration: none;
}
.sente_pagination__goto_input {
  margin-left: 8px;
  width: 28px;
  margin-right: 4px;
  height: 24px;
  padding: 0 4px;
  border: 1px solid #dae2ed;
  line-height: 24px;
  outline: none;
  text-align: right;
}
.sente_pagination__pages_link.disabled {
  color: #ccc;
  border-color: #ccc;
  background: #ecedef;
  cursor: default;
}
.sente_pagination__pages_link {
  display: inline-block;
  font-size: 14px;
  color: rgb(34, 34, 34);
  min-width: 18px;
  height: 26px;
  line-height: 26px;
  padding: 0 8px;
  border: 1px solid #dae2ed;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.sente_pagination__pages_link:hover {
  color: #ff7519;
  border-color: #ff7519;
  text-decoration: none;
}

.page_numbers {
  list-style: none;
  display: flex;
  margin-bottom: 0px;
}

.paginationItem {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 50%;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  background-color: #2580f8;
  color: #eff0f3;
  cursor: pointer;
}
.paginationItem:hover {
  background-color: #ff7519;
}
.ellipsisItem {
  padding: 0 12px;
  height: 32px;
  background-color: #2580f8 !important;
  color: #eff0f3;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  display: flex !important;
  margin: auto 4px;
  border-radius: 50% !important;
  font-size: 13px;
  line-height: 1.43;
  min-width: 32px;
}
.ellipsisItem:hover {
  background-color: #2580f8;
  color: #eff0f3;
}

.activeItem {
  background-color: #ff7519 !important;
  color: #eff0f3 !important;
}
