.account-title {
  width: 170px;
  height: 28px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
  line-height: 25px;
}

.table-container {
  box-shadow: 0 3px 6px rgba(44, 40, 40, 0.11);
  background-color: var(--white-color);
}
.account-setting-img {
  width: 103px;
  height: 103px;
  border-radius: 50%;
}
.account-img-container {
  margin-top: -80px;
  width: 59px;
  height: 33px;
  padding: 5px;
  border-radius: 3px;
  background-color: var(--blue-color);
  margin-left: 35px;
  margin-top: -20px;
}
.account-img-text {
  width: 25px;
  height: 22px;
  color: var(--white-color);
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;
}

.title-header {
  width: 168px;
  height: 28px;
  opacity: 0.38;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
  line-height: 25px;
}

.title-text {
  width: 161px;
  height: 28px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  /* text-transform: uppercase; */
}
.free-account-badge {
  width: 59px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--deep-yellow-color);
}

.badge-text {
  width: 33px;
  height: 22px;
  color: var(--white-color);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 22px;
  margin: 7px;
  text-transform: uppercase;
}
.reset-pwd {
  width: 170px;
  height: 28px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
  line-height: 28px;
}

.reset-api {
  /* Style for "Rectangle" */
  width: 250px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--deep-yellow-color) !important;
  background-color: var(--deep-yellow-color) !important;
}
.reset-btn-text {
  color: #fffff7;
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
}
.input-fields {
  border-radius: 5px;
  border: 1px solid #dadce0;
  background-color: var(--white-color);
}
