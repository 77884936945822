.modal-session {
  background: rgba(0, 0, 0, 0.5);
  transition: all ease 1s;
}

.sort-table {
  width: 146px;
  height: 26px;
  color: #293e40;
  font-size: 1rem;
  font-weight: 500;
}
.btn-link {
  text-decoration: none !important;
  cursor: pointer !important;
}

.header-dropdown {
  top: 50px;
  right: -10px;
}

a:hover {
  color: var(--deep-yellow-color) !important;
}
.tbl-row {
  border: 1px solid #d5e3d6;
  background-color: var(--white-color);
}

.trans-tbl-row {
  border: 1px solid #d5e3d6;
  background-color: var(--white-color);
}

.trans-tbl-data {
  font-size: 0.9rem;
}

.icon {
  fill: var(--white-color);
}
.svg-fill {
  fill: var(--deep-yellow-color);
}
.session-modal {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
}

.session-modal-margin {
  margin: 0 auto;
}
a {
  color: #202020 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

a,
u {
  text-decoration: none !important;
}

a:hover {
  color: var(--deep-yellow-color);
  text-decoration: none;
  background-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--deep-yellow-color);
  background-color: #eff0f3 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.08px;
}

.nav-buttons {
  padding: 0.2rem 0.2rem !important;
}

.input-checkbox {
  /* Style for "Rectangle" */
  width: 5px;
  height: 5px;
  border-radius: 4px;
  border: 1px solid #4c84ff;
  background-color: var(--white-color);
}

.input-checkbox-sm input {
  width: 15px;
  height: 15px;
  border: 1px solid #707070;
  background-color: var(--white-color);
}

.graph-text-active {
  width: 68px;
  height: 28px;
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}

.graph-text {
  width: 77px;
  height: 26px;
  color: #9c9a99;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}

.table-content {
  /* Style for "Table of c" */
  color: var(--dark-color);
  font-size: 1rem;
}
.modal-content {
  border-top: 0.2em solid #f87f0f !important;
}

.btn-outline-orange {
  color: var(--deep-yellow-color) !important;
  border-color: var(--deep-yellow-color) !important;
}

span.btn.btn-outline-orange:hover {
  color: var(--deep-yellow-color) !important;
  text-decoration: none;
  background-color: transparent;
}

.sign-up-text {
  color: #1a73e8;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 2;
}

.tcs_accepted_text {
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1;
}

.clear-close-text {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05px;
}

.close-btn {
  width: 95px;
  height: 42px;
  border-radius: 7px;
  border: 1px solid #707070;
  background-color: var(--white-color);
}

.footer-color {
  background-color: var(--deep-yellow-color) !important;
  /* border-radius: 0px 0px 25% 25% !important; */
}
.footer-color-blue {
  background-color: var(--blue-color) !important;
  /* border-radius: 0px 0px 25% 25% !important; */
}
.page-content > .row {
  margin: 25px;
}

.subscription-header {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.modal-header-text {
  font-size: 1rem;
  font-weight: 700;
}
.subscription-header-blue {
  color: var(--blue-color);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.subscription-subheader-blue {
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.subscription-subheader {
  color: #868686;
  font-size: 0.8rem;
  font-weight: 500;
}
.subscription-list {
  color: #7b7b7b;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.5;
}
.footer-card-text {
  color: var(--white-color);
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 2;
}
a,
u {
  text-decoration: none !important;
}

.sub-period {
  color: #868686;
  font-size: 0.8rem;
  font-weight: 500;
}
.prev-page {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.08px;
}
.tab-img {
  width: 50px;
  height: 25px;
  margin-top: -20px;
  margin-left: 27px;
}

.table td,
.table th {
  border-top: 1px solid #dee2e6 !important;
}

/* .fade {
  opacity: 0.9 !important;
} */
.ml250px {
  margin-left: 250px;
}

html {
  scroll-behavior: smooth;
}
label {
  font-size: 0.7rem;
}

.error {
  position: absolute;
  color: red;
  font-size: 0.6rem;
  line-height: 1.8;
}

.modal-content {
  border-top: 0.2em solid var(--deep-yellow-color);
}

.modal-dialog,
.modal-content {
  border-radius: 0.5em;
}

.modal-header {
  border-bottom-color: var(--white-color);
}

.content-box-large {
  margin-bottom: 10px;
  background: var(--white-color);
  border-radius: 10px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.content-box-header {
  min-height: 40px;
  font-size: 1rem;
  background: var(--white-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
}

.label-text {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}

.content-box-header:after {
  clear: both;
}

.otp-custom {
  justify-content: center;
  align-items: center;
  display: flex;
}

.otpWrapper {
  display: flex;
}

.otpInputWrapper {
  height: 2.5rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
  margin-right: 0.5rem;
}

.otpInput {
  width: 100%;
  height: inherit;
  border: 1px solid;
  border-radius: 10%;
  border-color: #cdcdcd;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  color: #4a4a4a;
  padding: 0 1px;
}

.iconStyles {
  list-style: none;
  padding: 0.25rem;
}
.icon-list-style {
  font-family: Arial;
}
.otpInput:focus {
  border-color: #20c4f4;
}

.otpInput:disabled {
  background-color: #eee;
  cursor: not-allowed;
}

.error > .otpInput,
.error > .otpInput:focus {
  border-color: #d0021b;
}

.errorMessage {
  margin: 8px 0 0 0;
  color: #d0021b;
  font-size: 0.8rem;
}

.payment-modal {
  justify-content: center;
}

@media (max-width: 450px) {
  .box {
    height: 350px;
  }
  .sentepay-logo {
    width: 250px !important;
  }
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
    text-align: center;
    height: 400px;
  }
}

@media (max-width: 480px) {
  .mobile_view {
    display: none;
  }
  .pagination .btn {
    height: 20px !important;
  }
  .table td,
  .table th {
    border-top: 1px solid #dee2e6 !important;
  }
  .page-content > .row {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1290px) {
  .page-content > .row {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
