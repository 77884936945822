.sidebar {
  display: block;
  background: #f87f0f 0% 0% no-repeat padding-box !important;
  border-radius: 0.75rem;
}

.sideNav {
  display: list-item !important;
  margin: 10px !important;
}

.contentBox,
.contentBoxLarge {
  margin-bottom: 0.75rem;
  background: #fff;
  border-radius: 0.75rem;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.sidebar2 {
  display: block;
  background: #f87f0f 0% 0% no-repeat padding-box;
  border-radius: 12px;
}
.mobileViewDisplay {
  display: none !important;
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .fixedSidebar {
    position: fixed;
    width: 15%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .fixedSidebar {
    position: fixed;
    width: 15%;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  .mobileView {
    display: none;
  }

  .mobileViewDisplay {
    display: flex !important;
  }
}
