.faq-title {
  width: 69px;
  height: 40px;
  color: #434343;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.table-content-container {
  width: 438px;
  height: auto;
  opacity: 0.5;
}
.api-item {
  color: var(--dark-color);
  font-size: 1rem;
  font-weight: 500;
}

.api-item li a:hover {
  color: #434343;
  text-decoration: none;
}

.active-item {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.mail-img-text {
  width: 73px;
  height: 21px;
  color: #434343;
  font-size: 1rem;
  font-weight: 700;
}

.faq-breadcrumb {
  width: 101px;
  height: 21px;
  color: #8b8b8b;
  font-size: 1rem;
}

.list-number {
  width: 9px;
  height: 26px;
  opacity: 0.46;
  color: #434343;
  font-size: 1rem;
  font-weight: 500;
}

.list-title {
  width: 287px;
  height: 26px;
  color: #434343;
  font-size: 1rem;
  font-weight: 500;
}
