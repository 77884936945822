.content-box {
  margin-bottom: 10px;
  background: var(--white-color);
  border-radius: 10px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}
.sidebar .nav > li > a,
.sidebar1 .nav > li > a {
  font-size: 1rem;
  line-height: 22px;
  padding: 15px 15px;
  display: block;
  background: none;
  text-decoration: none;
  border-top: 0px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: SemiBold 20px/22px Arial;
  letter-spacing: 0px;
  color: var(--white-color) !important;
  font-weight: 500;
  opacity: 1;
}
.sidebar_content {
  background: var(--deep-yellow-color) 0% 0% no-repeat padding-box;
  border-radius: 12px;
}

.sidebar .nav li a:hover,
.sidebar .nav li.current > a {
  background: var(--white-color) !important;
  margin-left: -10px;
  margin-right: -10px;
  border-bottom: 0px;
  letter-spacing: 0px;
  text-align: left;
  font: SemiBold 20px/22px Arial;
  letter-spacing: 0px;
  color: var(--deep-yellow-color) !important;
  font-weight: 500;
}
.navbar-toggler-left {
  color: #f87f0f;
  display: none !important;
  margin-top: 2rem !important;
}

.sidebar_nav {
  display: list-item !important;
  margin: 10px;
}
.mobile_view_display {
  display: none !important;
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .fixed-sidebar {
    position: fixed;
    width: 15%;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  /* .mobileView {
    display: none;
  } */

  .mobile_view_display {
    display: flex !important;
  }
  .navbar-toggler-left {
    display: block !important;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .fixed-sidebar {
    position: fixed;
    width: 15%;
  }
}
@media (max-width: 991px) {
  .sidebar,
  content-box {
    margin-bottom: 30px;
    width: 100%;
    float: none;
    position: relative;
  }
}

/* Mobile phones */
/* @media (max-width: 480px) {
  .mobile_view_display {
    display: flex !important;
  }
} */
