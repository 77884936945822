.doc-header {
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
}

.form-control,
.btn {
  box-shadow: none !important;
  outline: none !important;
}

.required-text {
  width: 424px;
  height: 35px;
  color: var(--deep-yellow-color);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 35px;
}

.caption-text {
  width: 131px;
  height: 28px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
}

.payment-btn {
  width: 160px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--deep-yellow-color);
  background-color: var(--deep-yellow-color) !important;
  color: var(--white-color);
}

.ticket-tab-active {
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 15px;
  border: none !important;
}

.nav-link {
  font-weight: 500 !important;
  letter-spacing: -0.08px !important;
}

.upgrade-2 {
  color: #0e0e0d;
  font-weight: 600;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.08px;
}

.upgrade-1 {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.08px;
}
.loader-text-2 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #0e0e0d;
}

.loader-text {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.1px;
}

.pay-btn-text {
  color: var(--white-color);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05px;
}
.pay-btn {
  border-radius: 7px;
  background-color: var(--deep-yellow-color) !important;
}
.modal-footer {
  border-top-color: var(--white-color);
}
