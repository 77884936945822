.pass-reset {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.08px;
  text-align: center;
  justify-content: center;
}
.pass-reset-text {
  width: 570px;
  height: 46px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.07px;
}
