.ticket-header {
  color: var(--deep-yellow-color);
  font-size: 0.8rem;
  font-weight: 700;
}
.ticket-text {
  color: #707070;
  font-size: 0.8rem;
  font-weight: 500;
}
.ticket-tab {
  color: #707070;
  font-size: 0.8rem;
  font-weight: 500;
}
