.login-wrapper {
  text-align: center;
}

.login-wrapper .box {
  margin: 0 auto;
  float: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px, rgba(0, 0, 0, 0.35) 0 0 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px, rgba(0, 0, 0, 0.35) 0 0 1px;
  -ms-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px, rgba(0, 0, 0, 0.35) 0 0 1px;
  -o-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px, rgba(0, 0, 0, 0.35) 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px, rgba(0, 0, 0, 0.35) 0 0 1px;
  background: var(--white-color);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}
.login-wrapper .box .content-wrap {
  width: 92%;
  margin: 0 auto;
}
.login-wrapper .box h6 {
  text-transform: uppercase;
  margin-bottom: 60px;
  font-size: 1.2rem;
  font-weight: 600;
}
.login-wrapper .box input[type="text"],
.login-wrapper .box input[type="password"] {
  font-size: 1rem;
  height: 2.5rem;
  margin-bottom: 0.625rem;
  border-color: #b2bfc7;
}
.login-wrapper .box input[type="text"]:focus,
.login-wrapper .box input[type="password"]:focus {
  border: 1px solid #28a0e5;
  outline: none;
  -webkit-box-shadow: inset 0 1px 2px #ddd, 0px 0 5px #28a0e5;
  -moz-box-shadow: inset 0 1px 2px #ddd, 0px 0 5px #28a0e5;
  -ms-box-shadow: inset 0 1px 2px #ddd, 0px 0 5px #28a0e5;
  -o-box-shadow: inset 0 1px 2px #ddd, 0px 0 5px #28a0e5;
  box-shadow: inset 0 1px 2px #dddddd, 0px 0 5px #28a0e5;
}
.login-wrapper .box input[type="password"] {
  margin-bottom: 0.625rem;
}

.login-wrapper .box .action {
  position: relative;
  top: 30px;
  padding: 15px 0px;
}
.login-wrapper .box .signup {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 7px 25px;
  border-radius: 5px;
}
.login-wrapper .already {
  margin: 0 auto;
  float: none;
  text-align: center;
  font-size: 1rem;
}
.login-wrapper .already p {
  display: inline-block;
  color: #222;
}
.login-wrapper .already a {
  color: #222;
  margin-left: 7px;
  border-bottom: 1px solid;
}
.login-wrapper .already a:hover {
  text-decoration: none;
  color: var(--dark-color);
  border-bottom-color: var(--dark-color);
}

.login-wrapper .box .social {
  margin: 0 auto;
  width: 90%;
}
.login-wrapper .box .social a.face_login {
  background: #6887c4;
  background: -moz-linear-gradient(top, #6887c4 0%, #4566a9 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #6887c4),
    color-stop(100%, #4566a9)
  );
  background: -webkit-linear-gradient(top, #6887c4 0%, #4566a9 100%);
  background: -o-linear-gradient(top, #6887c4 0%, #4566a9 100%);
  background: -ms-linear-gradient(top, #6887c4 0%, #4566a9 100%);
  background: linear-gradient(to bottom, #6887c4 0%, #4566a9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6887c4', endColorstr='#4566a9',GradientType=0 );
  border: 1px solid #3b4868;
  border-radius: 3px 3px 3px 3px;
  display: inline-block;
  height: 37px;
  overflow: hidden;
  width: 99%;

  text-decoration: none !important;
}
.login-wrapper .box .social a.face_login:hover {
  background: #6887c4;
  background: -moz-linear-gradient(top, #6887c4 0%, #5773ac 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #6887c4),
    color-stop(100%, #5773ac)
  );
  background: -webkit-linear-gradient(top, #6887c4 0%, #5773ac 100%);
  background: -o-linear-gradient(top, #6887c4 0%, #5773ac 100%);
  background: -ms-linear-gradient(top, #6887c4 0%, #5773ac 100%);
  background: linear-gradient(to bottom, #6887c4 0%, #5773ac 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6887c4', endColorstr='#5773AC',GradientType=0 );
}
.login-wrapper .box .social a.face_login span.face_icon {
  background-color: #39599f;
  border-radius: 3px 0 0 3px;
  float: left;
  height: 37px;
  text-align: center;
  width: 51px;
}
.login-wrapper .box .social a.face_login span.face_icon img {
  margin-top: 5px;
}
.login-wrapper .box .social a.face_login span.text {
  color: var(--white-color);
  float: left;
  font-size: 1rem;
  font-weight: bold;
  margin: 6px 0 0 25px;
  text-shadow: 1px 1px 0 #39599f;
}
.login-wrapper .box .division {
  display: inline-block;
  margin: 17px 0 23px;
  position: relative;
  text-align: center;
  width: 100%;
}
.login-wrapper .box .division hr {
  border-color: #e2e2e4;
  border-width: 1px;
  margin: 0;
  position: absolute;
  width: 40%;
}
.login-wrapper .box .division hr.left {
  top: 13px;
}
.login-wrapper .box .division hr.right {
  bottom: 6px;
  right: 0;
}
.login-wrapper .box .division span {
  color: #666666;
  font-size: 1.2rem;
}

.login-wrapper-mr-200 {
  /* margin-right: 200px; */
  padding-bottom: 50px;
}
.login-body {
  top: 0px;
  left: 0px;
  width: auto;
  height: auto;
  background: var(--white-color) 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow-x: hidden;
}

.login-nav-btn {
  color: #64686d;
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 1;
}
.welcome-text {
  color: var(--dark-color);
  font-size: 2.3rem;
  font-weight: 400;
  letter-spacing: -0.15px;
}
.sente-pay-text-bold {
  color: var(--deep-yellow-color);
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: -0.2px;
  font-family: "Arial - Bold";
  font-weight: 700;
}
.sente-pay-text {
  color: var(--deep-yellow-color);
  font-size: 2.8rem;
  font-weight: 300;
  letter-spacing: -0.2px;
}
.welcome-note {
  color: #64686d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.8;
}
.custom-box-wrapper {
  border: 1px solid rgb(173, 34, 34);
  margin-right: 200px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 1rem;
}
.sign-in-btn {
  border: 1px solid #dadce0;
  background-color: var(--deep-yellow-color) !important;
}
.sign-up-text2 {
  color: #1070e6;
  font-family: "Arial - Regular";
  font-weight: 400;
}
.login-footnote {
  margin-top: 350px;
  height: "100vh";
  align-content: space-between;
}
.privacy-text {
  color: #64686d;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
}
.login-svg {
  height: 250px;
  width: 1000px;
}
.login-mobile {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  .login-svg {
    height: 300px;
    width: 600px;
  }
  .login-svg-container {
    display: none;
  }
}
@media (max-width: 480px) {
  .login-wrapper-mr-200 {
    margin-right: auto;
    padding-bottom: auto;
  }
  .login-mobile {
    padding-top: 150px;
    padding-left: 50px;
  }
}
