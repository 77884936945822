.page-content > .row {
  margin: 25px;
}
.panel-body {
  clear: both;
}
.recent-trans {
  width: 251px;
  height: 26px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2;
  text-transform: uppercase;
}
.ugx-3300 {
  color: var(--deep-yellow-color);
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.wallet_balance {
  color: var(--deep-yellow-color);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.ugx {
  color: var(--deep-yellow-color);
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
}

.curr_percent {
  width: 73px;
  height: 20px;
  color: var(--blue-color);
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}
.ugx-currency {
  color: #f87f0f;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.ugx-amount {
  color: #f87f0f;
  text-transform: uppercase;
}
.curr-bal {
  color: #0e0e0d;
  line-height: 2;
}
.pay_col_bal {
  height: 25px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}
.withdraw-btn {
  width: 225px;
  height: 45px;
  padding: 12px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background-color: #2580f8 !important;
  border: 1px solid #2580f8;
}
.withdraw-btn-text {
  color: #ffffff !important;
  font-size: 22px;
  font-weight: 300;
}

.withdraw-btn:hover,
.withdraw-btn:hover > span {
  color: #fff;
  background-color: #2580f8 !important;
}

.report-header {
  width: 245px;
  height: 31px;
  color: #0e0e0d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}
.upgrade-btn {
  width: 160px;
  height: 40px;
  border-radius: 8px;
  background-color: var(--blue-color);
}

.dashboard-icon {
  background-image: url(/public/images/dashboard-icon.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-right: 25px;
}

@media (max-width: 480px) {
  .withdraw-btn {
    width: 225px !important;
    height: 56px;
    padding: 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    border: 1px solid #2580f8;
  }
}

@media (max-width: 280px) {
  .withdraw-btn {
    width: 150px !important;
    height: 50px;
    padding: 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    border: 1px solid #2580f8;
  }
  .navbar-toggler-icon {
    width: 0.8em !important;
    height: 0.8em !important;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1290px) {
  .withdraw-btn {
    width: 150px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ugx-amount {
    font-size: 0.7rem !important;
    font-weight: 700 !important;
  }
  .login-wrapper {
    padding-left: 2rem !important;
  }
  .ugx-currency {
    font-size: 0.7rem !important;
    font-weight: 700 !important;
  }
}
