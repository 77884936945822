@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./login/login.css";
@import "./login/forgotPassword.css";
@import "./sidebar/sidebar.css";
@import "./settings/accountSettings.css";
@import "./company/company.css";
@import "./faq/faq.css";
@import "./dashboard/dashboard.css";
@import "./footer/footer.css";
@import "./header/header.css";
@import "./common/common.css";
@import "./support_response/support_response.css";
@import "./stepper/stepper.css";

:root {
  --light-color: #f7f7f7;
  --white-color: #ffffff;
  --dark-color: #000000;
  --main-font-family: Arial;
  --deep-yellow-color: #f87f0f;
  --blue-color: #2580f8;
}

body {
  font-family: var(--main-font-family) !important;
  background-color: var(--light-color);
  position: relative;
  margin: 0px;
  font-size: 1rem;
  padding: 0px;
}
